import React, { useState } from 'react';
import OpenAI from 'openai';

const client = new OpenAI({
  apiKey: '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', // This is the default and can be omitted
  dangerouslyAllowBrowser: true,
});


const ProductIdeaTester = () => {
  const [formData, setFormData] = useState({
    product: 'Bike Repairs',
    features: 'We repair all kinds of bikes',
    problem: 'I need to get my bike fixed',
    email: 'bikerepairs@bikershopboston.com',
    cta: 'schedule',
    ctaOther: '',
    tone: 'friendly',
    jargon: ''
  });
  // random companies that could be interested in purchasing the bike repair service
  const [companies, setCompanies] = useState([
    { name: 'Municipality of Boston', contact: 'procurement@boston.gov' },
    { name: 'Google office in Berlin', contact: 'officesupplies@google.de' },
    { name: 'Sports club "Night Rider"', contact: 'nightrider@sportsclub.com' },
    { name: 'Athletic club "The Runner"', contact: 'runner@athleticclub.com' },
    { name: 'Hiking club "Mountain View"', contact: 'hikingclub@mountainview.com' },
  ]);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [generatedEmails, setGeneratedEmails] = useState(Array(5).fill(''));
  const [modificationLevel, setModificationLevel] = useState(50);
  const [gpt4Prompts, setGpt4Prompts] = useState({
    templateGeneration: 'Generate an email template based on the following information:',
    emailGeneration: 'You will be given a template and a recipient company. You will then generate an email for that company based on the template. You will also be given a modification level, from 0 to 100. You must modify the email to be more personal and relevant to the company, while still adhering to the template and modification level. A level of 0 means the email should be exactly the same as the template, and a level of 100 means the email should be completely rephrased from the template.',
    companyGeneration: 'Generate 5 random company names and contact information'
  });
  const [disableGPT4, setDisableGPT4] = useState(false);
  const [debugLog, setDebugLog] = useState([]);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCompanyChange = (index, field, value) => {
    const newCompanies = [...companies];
    newCompanies[index] = { ...newCompanies[index], [field]: value };
    setCompanies(newCompanies);
  };

  const generateEmailTemplate = async () => {
    const prompt = `${gpt4Prompts.templateGeneration}\n<formData>
  <product>${formData.product}</product>
  <features>${formData.features}</features>
  <problem>${formData.problem}</problem>
  <email>${formData.email}</email>
  <cta>${formData.cta}</cta>
  <ctaOther>${formData.ctaOther}</ctaOther>
  <tone>${formData.tone}</tone>
  <jargon>${formData.jargon}</jargon>
</formData>`;
    const result = await callGPT4API(prompt);
    setEmailTemplate(result);
    logDebug('Generated email template', prompt, result);
  };

  const generateCompanies = async () => {
    const result = await callGPT4API(gpt4Prompts.companyGeneration);
    const newCompanies = JSON.parse(result);
    setCompanies(newCompanies);
    logDebug('Generated companies', gpt4Prompts.companyGeneration, result);
  };

  const generateEmails = async () => {
    const newEmails = await Promise.all(companies.map(async (company, index) => {
      const prompt = `${gpt4Prompts.emailGeneration}\nTemplate: ${emailTemplate}\nInformation about the recipient company: ${JSON.stringify(company)}\nModification level: ${modificationLevel}`;
      const result = await callGPT4API(prompt);
      logDebug(`Generated email for company ${index + 1}`, prompt, result);
      return result;
    }));
    setGeneratedEmails(newEmails);
  };

  const callGPT4API = async (prompt) => {
    if (disableGPT4) {
      return prompt;
    }
    const chatCompletion = await client.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o-mini',
    });
    logDebug('OpenAI API call parameters', prompt, JSON.stringify(chatCompletion));
    return chatCompletion.choices[0].message.content;
  };

  const logDebug = (action, input, output) => {
    setDebugLog(prev => [...prev, { timestamp: new Date().toISOString(), action, input, output }]);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Product Idea Tester</h1>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Product Information Form</h2>
        <form onSubmit={(e) => { e.preventDefault(); generateEmailTemplate(); }}>
          <input
            name="product"
            value={formData.product}
            onChange={handleFormChange}
            placeholder="What is the main product / service you are selling?"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="features"
            value={formData.features}
            onChange={handleFormChange}
            placeholder="What are the key features of your product / service?"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="problem"
            value={formData.problem}
            onChange={handleFormChange}
            placeholder="What problem does your product / service solve for the client?"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="email"
            value={formData.email}
            onChange={handleFormChange}
            placeholder="Please formulate your email in your own words."
            className="w-full p-2 mb-2 border rounded"
          />
          <select
            name="cta"
            value={formData.cta}
            onChange={handleFormChange}
            className="w-full p-2 mb-2 border rounded"
          >
            <option value="">Select CTA</option>
            <option value="schedule">Schedule a call</option>
            <option value="visit">Visit your website</option>
            <option value="respond">Respond to your email</option>
            <option value="other">Other</option>
          </select>
          {formData.cta === 'other' && (
            <input
              name="ctaOther"
              value={formData.ctaOther}
              onChange={handleFormChange}
              placeholder="Other CTA"
              className="w-full p-2 mb-2 border rounded"
            />
          )}
          <select
            name="tone"
            value={formData.tone}
            onChange={handleFormChange}
            className="w-full p-2 mb-2 border rounded"
          >
            <option value="">Select tone</option>
            <option value="formal">Formal</option>
            <option value="friendly">Friendly</option>
            <option value="casual">Casual</option>
            <option value="friendly_emoji">Friendly with emojis</option>
          </select>
          <input
            name="jargon"
            value={formData.jargon}
            onChange={handleFormChange}
            placeholder="Industry jargon or terminology to include or avoid"
            className="w-full p-2 mb-2 border rounded"
          />
          <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Generate Email Template</button>
        </form>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Email Template</h2>
        <textarea
          value={emailTemplate}
          onChange={(e) => setEmailTemplate(e.target.value)}
          className="w-full p-2 mb-2 border rounded"
          rows={10}
        />
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Company Information</h2>
        {companies.map((company, index) => (
          <div key={index} className="mb-2">
            <input
              value={company.name}
              onChange={(e) => handleCompanyChange(index, 'name', e.target.value)}
              placeholder={`Company ${index + 1} Name`}
              className="w-full p-2 mb-1 border rounded"
            />
            <input
              value={company.contact}
              onChange={(e) => handleCompanyChange(index, 'contact', e.target.value)}
              placeholder={`Company ${index + 1} Contact`}
              className="w-full p-2 mb-1 border rounded"
            />
          </div>
        ))}
        <button onClick={generateCompanies} className="w-full p-2 bg-green-500 text-white rounded">Generate Random Companies</button>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Generated Emails</h2>
        <input
          type="range"
          value={modificationLevel}
          onChange={(e) => setModificationLevel(e.target.value)}
          min={0}
          max={100}
          step={1}
          className="w-full mb-2"
        />
        <p>Modification Level: {modificationLevel}%</p>
        <button onClick={generateEmails} className="w-full p-2 mb-2 bg-purple-500 text-white rounded">Generate Emails</button>
        {generatedEmails.map((email, index) => (
          <textarea
            key={index}
            value={email}
            onChange={(e) => {
              const newEmails = [...generatedEmails];
              newEmails[index] = e.target.value;
              setGeneratedEmails(newEmails);
            }}
            className="w-full p-2 mb-2 border rounded"
            rows={5}
          />
        ))}
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">GPT-4 Prompts</h2>
        {Object.entries(gpt4Prompts).map(([key, value]) => (
          <div key={key} className="mb-2">
            <label className="block mb-1">{key}</label>
            <textarea
              value={value}
              onChange={(e) => setGpt4Prompts({ ...gpt4Prompts, [key]: e.target.value })}
              className="w-full p-2 border rounded"
              rows={3}
            />
          </div>
        ))}
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Debug Settings</h2>
        <label className="flex items-center mb-2">
          <input
            type="checkbox"
            checked={disableGPT4}
            onChange={(e) => setDisableGPT4(e.target.checked)}
            className="mr-2"
          />
          Disable GPT-4 API Calls
        </label>
      </div>

      <div>
        <h2 className="text-xl font-bold mb-2">Debug Log</h2>
        <pre className="whitespace-pre-wrap border p-2 rounded">
          {JSON.stringify(debugLog, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default ProductIdeaTester;
